import {
  delay,
  take,
  call,
  fork,
  put,
  all,
  cancel,
  select
} from "redux-saga/effects";
import { selectors } from "reducers";
import { Types } from "actions/phone";
import { Types as AuthTypes } from "actions/authentication";
import { errorMessage, message } from "actions/message";
import * as api from "utils/api";
import { Device } from "twilio-client";
import { listenDeviceEvents } from "./event-handler";
import watchConnection from "./connection-handler";
import watchIncomingCall from "./incoming-call";
import { isLogoutResponse, isUnauthorizedResponse } from "sagas/authentication";
import moment from "moment";

const Queue_Polling_Delay = 160000;

export default function* root() {
  yield all([
    call(watchConnection),
    call(watchIncomingCall),
    call(watchInit),
    call(watchCall),
    call(watchAnswerQueue),
    call(watchTransfer),
    call(watchAcceptCall),
    call(watchRejectCall),
    call(watchIVROutbound),
    call(watchAutomatedVoicemailRequest),
    call(watchReconnectIVRPatientWithIVRSystem)
  ]);
}

function* watchInit() {
  try {
    while (true) {
      yield take(Types.OPEN_PHONE);
      const twilioToken = yield select(state =>
        selectors.getTwilioToken(state)
      );
      const device = yield call(Device.setup, twilioToken, {
        closeProtection: true,
        codecPreferences: ["opus", "pcmu"]
      });
      const listenTask = yield fork(listenDeviceEvents, device);
      const queueSyncTask = yield fork(fetchQueueOnInterval);
      yield take([
        Types.CLOSE_PHONE,
        AuthTypes.LOGOUT_USER_INACTIVE,
        isLogoutResponse,
        isUnauthorizedResponse
      ]);
      Device.destroy();
      if (queueSyncTask) yield cancel(queueSyncTask);
      if (listenTask) yield cancel(listenTask);
    }
  } catch (error) {
    console.error(error); /* eslint-disable-line */
  }
}

function* fetchQueueOnInterval() {
  while (true) {
    try {
      const queues = yield call(api.get, "twilio/queue");
      yield put({
        type: "SET_PHONE_QUEUES",
        payload: queues
      });
    } catch (error) {
      yield put({
        type: Types.QUEUE_UPDATE_FAILED,
        error: true,
        payload: { status: error.status }
      });
    } finally {
      yield delay(Queue_Polling_Delay);
    }
  }
}

export function* watchAnswerQueue() {
  while (true) {
    try {
      const {
        payload: { to = "", from }
      } = yield take(Types.ANSWER_QUEUE);
      const queueCall = yield call(api.post, "twilio/New/Outgoing/Queue", {
        to,
        from
      });
      yield put({ type: Types.SET_CALL_DIRECTION, payload: "inbound" });
      yield put({ type: Types.SET_QUEUE_CALL_TO_ANSWER, payload: queueCall });
      yield put({ type: Types.SET_QUEUE_CALL_ACTIVE, payload: true });
      yield take(Types.TWILIO_EVENT_DISCONNECT);
      yield put({ type: Types.SET_QUEUE_CALL_ACTIVE, payload: false });
      yield put({ type: Types.SET_QUEUE_CALL_TO_ANSWER, payload: null });
      yield put({ type: Types.SET_CALL_DIRECTION, payload: "" });
      yield put({ type: Types.SET_DIALED_NUMBER, payload: "" });
      yield put({ type: Types.SET_CONNECTED_INCOMING_NUMBER, payload: "" });
    } catch (error) {
      yield put({ type: Types.PHONE_CALL_ERROR, error });
      yield put(errorMessage("An error occurred while making your call."));
    }
  }
}

export function* watchCall() {
  while (true) {
    try {
      const {
        payload: { to = "", from, patientToCall, ...rest }
      } = yield take(Types.PHONE_CALL);
      yield call(Device.connect, {
        To: to,
        From: from,
        patientGuid: patientToCall,
        ...rest
      });
      yield put({ type: Types.SET_CALL_DIRECTION, payload: "outbound" });
      yield put({ type: Types.SET_DIALED_NUMBER, payload: to });
      yield take(Types.TWILIO_EVENT_DISCONNECT);
      yield put({ type: Types.SET_QUEUE_CALL_ACTIVE, payload: false });
      yield put({ type: Types.SET_QUEUE_CALL_TO_ANSWER, payload: null });
      yield put({ type: Types.SET_CALL_DIRECTION, payload: "" });
      yield put({ type: Types.SET_DIALED_NUMBER, payload: "" });
      yield put({ type: Types.SET_CONNECTED_INCOMING_NUMBER, payload: "" });
    } catch (error) {
      yield put({ type: Types.PHONE_CALL_ERROR, error });
      yield put(errorMessage("An error occurred while making your call."));
    }
  }
}

function* watchAcceptCall() {
  while (true) {
    yield take(Types.ACCEPT_INCOMING);
    const connection = Device.activeConnection();
    if (connection) {
      connection.accept();
    }
  }
}

function* watchRejectCall() {
  while (true) {
    yield take(Types.REJECT_INCOMING);
    const connection = Device.activeConnection();
    if (connection) {
      connection.reject();
    }
  }
}

function* watchTransfer() {
  while (true) {
    try {
      while (true) {
        const { type, payload } = yield take([
          Types.TRANSFER,
          Types.CANCEL_TRANSFER,
          Types.TWILIO_EVENT_DISCONNECT,
          Types.TWILIO_EVENT_OFFLINE
        ]);
        if (type === Types.TRANSFER) {
          try {
            const getCallDirection = state =>
              state.getIn(["phone"]).callDirection;
            const callDirection = yield select(getCallDirection);
            const response = yield call(
              api.put,
              `twilio/New/Transfer/Call/${callDirection}`,
              { number: payload.number, transferInProgress: true }
            );
            const callIsInConference = response.callsInConference;
            if (callIsInConference) {
              if (response.transferredParty.connected) {
                yield put({
                  type: Types.SET_TRANSFER_NUMBER_IN_CONFERENCE_TRUE
                });
              }
            }
            yield put({
              type: Types.SET_TRANSFER_NUMBER,
              payload: ""
            });
          } catch (e) {
            yield put(message("Failed To Transfer Call"));
          }
        } else if (type === Types.CANCEL_TRANSFER) {
          try {
            //eventually it'd be nice for party conference calls to pass which trasnfer number to cancel from a drop down
            //the back end is set up currently to take a callSid, and to cancel it based off that info
            //currently if no callSid is passed, all third part legs will cancel
            const getCallDirection = state =>
              state.getIn(["phone"]).callDirection;
            const callDirection = yield select(getCallDirection);
            const { success, fail } = yield call(
              api.post,
              `twilio/New/Transfer/Cancel/${callDirection}`
            );
            yield put({
              type: Types.SET_TRANSFER_NUMBER,
              payload: ""
            });
            if (success.length > 0)
              yield put(
                message(`Successfully ended ${success.length} call legs`)
              );
            if (fail.length > 0)
              yield put(
                message(
                  `An error occurred while attempting to end ${fail.length} call legs`
                )
              );
          } catch (e) {
            yield put(errorMessage(`An error occurred on your cancel. ${e}`));
          }
        } else {
          break;
        }
      }
      yield put({ type: Types.SET_TRANSFER_IN_PROGRESS, payload: false });
    } catch (_) {
      yield put(errorMessage("An error occurred on your transfer."));
      yield call(Device.disconnectAll);
      yield put({ type: Types.SET_TRANSFER_IN_PROGRESS, payload: false });
    }
  }
}

function* watchIVROutbound() {
  while (true) {
    try {
      const {
        payload: { toArray }
      } = yield take(Types.IVR_PHONE_CALLS);

      yield call(api.post, "IVR/StartIVRThreads", toArray);
    } catch (_) {
      yield put(errorMessage("An error occurred while making your call."));
    }
  }
}

function* watchAutomatedVoicemailRequest() {
  while (true) {
    try {
      const {
        payload: { patientGuid }
      } = yield take(Types.PLAY_AUTOMATED_VOICEMAIL);
      const connection = Device.activeConnection();
      const dialedNumber = connection && connection.message.To.replace("+", "");
      const {
        message: status,
        outreachDate,
        outreachType
      } = yield call(
        api.post,
        `twilio/new/transfer/RedirectCallIntoVoicemailSay/${dialedNumber}/${patientGuid}`
      );
      yield put(
        message(
          `${status}, changed outreach to ${outreachType}  ${moment(
            outreachDate
          ).format("L")}`
        )
      );
      yield put({ type: Types.PLAY_AUTOMATED_VOICEMAIL_SUCCESS });
      yield put({ type: Types.HANGUP });
    } catch (error) {
      yield put(
        errorMessage(
          `An error occurred while trying to leave an automated voicemail. ${error}`
        )
      );
    }
  }
}

function* watchReconnectIVRPatientWithIVRSystem() {
  while (true) {
    try {
      const {
        payload: { patientGuid }
      } = yield take(Types.RECONNECT_IVR_PATIENT_WITH_IVR_SYSTEM);
      const getFrom = state => state.getIn(["phone"]).connectedIncomingNumber;
      const outsidePartyNumber = yield select(getFrom);
      const response = yield call(
        api.post,
        `ivr/RedirectCallBackInIVR/${outsidePartyNumber}/${patientGuid}`
      );
      yield put(message(response));
      yield put({ type: Types.HANGUP });
    } catch (error) {
      yield put(
        errorMessage(
          `An error occurred while trying to connect this IVR patient into the IVR System. ${error}`
        )
      );
    }
  }
}
